<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Compra Realizada com Sucesso</h2>
      </div>
    </div>
    <b-card bg-variant="light">
      <b-container class="box-body">
        <div class="col-sm-12">
          <a href="https://www.retextoar.com.br">
            <span>
              <div style="text-align: center">
                <img
                  height="40"
                  src="https://storage.googleapis.com/retextoar-publico/app/imagens/logotipo_retextoar.png"
                />
              </div>
            </span>
          </a>
        </div>
        <div align="center">
          <div style="margin-top: 20px">
            <div class="sucessful-text">
              Compra realizada <br />
              com sucesso
            </div>
            <div align="center">
              <br />
                <img
                  src="https://storage.googleapis.com/retextoar-publico/app/imagens/ilustra06_confirmacaopagamento.png"
                />
              <p style="opacity: 0.7">Olá,</p>
              <p style="opacity: 0.7">Seus créditos já foram adicionados.</p>
              <br />
            </div>
            <br />
          </div>
        </div>

      <div class="col-sm-12 button-container">
            <b-button
              class="next"
              variant="primary"
              @click="onClickTranscriptionButton()"
              >Transcrições</b-button
            >
      </div>
      </b-container>
    </b-card>
  </section>
</template>

<script>
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    methods: {
      onClickTranscriptionButton () {
        this.$router.push({ name: 'transcriptions' });
      }
    }
  };
</script>

<style lang='scss' scoped>
.section-title h2 {
  margin-left: 5px;
}

.box-body {
  border-top-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 50px;
  background-color: #ffffff;
  display: grid;
}

.sucessful-text {
  opacity: 0.7;
  font-size: 23px;
  font-family: Arial;
  color: #333333;
  font-weight: bold;
}

.next{
  margin: auto;
  width: 150px;
}

.button-container{
  display: grid;
}

</style>
